<template>
  <PageContent :title="$t('architectural.detailsTitle')">
    <div class="box is-8">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="form-grid">
                <div
                  class="column is-8 has-text-left"
                  v-if="
                    account &&
                      account != undefined &&
                      account.financialInstitution.name &&
                      account.financialInstitution.name != undefined &&
                      account.name &&
                      account.name != undefined
                  "
                >
                  <h4>{{ `${account.financialInstitution.name} - ${account.name}` }}</h4>
                </div>
                <div class="column is-8">
                  <valid-select
                    placeholder="Account Type"
                    vid="accountType"
                    rules="required"
                    v-model="account.bankAccountTypeID"
                  >
                    <option
                      v-for="option in bankAccountTypes"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="column is-8">
                  <valid-select
                    placeholder="Linked Account"
                    vid="linkedAccount"
                    v-model="account.bankDataProviderAccountID"
                  >
                    <option
                      v-for="option in plaidAccountIds"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="column is-8">
                  <valid-select
                    placeholder="Asset Account"
                    vid="assetAccount"
                    rules="required"
                    v-model="account.bankGLAccountID"
                  >
                    <option
                      v-for="option in assetAccounts"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="column is-8">
                  <valid-select
                    placeholder="Interest Income"
                    vid="interestIncome"
                    rules="required"
                    v-model="account.interestIncomeGLAccountID"
                  >
                    <option
                      v-for="option in interestIncomeAccounts"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="column is-8">
                  <valid-input
                    name="Account Number"
                    maxlength="34"
                    type="text"
                    vid="accountNumber"
                    placeholder="Account Number"
                    rules="required|max:34|numeric"
                    v-model="account.accountNumber"
                  />
                </div>
                <div class="column is-8">
                  <valid-input
                    name="Routing Number"
                    maxlength="9"
                    type="text"
                    vid="routingNumber"
                    placeholder="Routing Number"
                    rules="required|max:9|length:9|numeric"
                    v-model="account.routingNumber"
                  />
                </div>
                <div class="column is-8">
                  Account Closed:&nbsp;
                  <b-checkbox class="has-left-text" v-model="account.isClosed">{{
                    account.isClosed
                  }}</b-checkbox>
                  &nbsp;{{ account.ClosedDate }}
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
            <button type="submit" class="button is-primary is-rounded" :disabled="loading">
              Update
            </button>
          </form>
        </transition>
      </ValidationObserver>
    </div>
    <div class="box is-8">
      <div class="list-content" v-if="bankAccountListings && bankAccountListings != undefined">
        <b-table
          style="max-height:200px; overflow: auto;"
          :data="bankAccountListings"
          :loading="loading"
          :striped="true"
          :sticky-header="true"
          :selected.sync="selectedRow"
        >
          <template slot="empty">
            <empty-list v-if="!loading" />
          </template>

          <b-table-column label="Date" field="modifiedDate" sortable centered v-slot="props">{{
            formatDateFn(props.row.modifiedDate)
          }}</b-table-column>
          <b-table-column label="Payee" field="name" sortable centered v-slot="props">{{
            formatFn(props.row.bankAccount.name)
          }}</b-table-column>
          <b-table-column label="Description" field="description" sortable v-slot="props">{{
            formatFn(props.row.description)
          }}</b-table-column>
          <b-table-column field="Amount" label="Balance" sortable v-slot="props">{{
            formatNumberFn(props.row.amount) | currency
          }}</b-table-column>
          <b-table-column label="Check Number" field="checkNumber" sortable v-slot="props">{{
            formatFn(props.row.checkNumber)
          }}</b-table-column>
          <b-table-column label="Cleared Date" field="clearedDate" v-slot="props">{{
            formatDateFn(props.row.clearedDate)
          }}</b-table-column>
          <b-table-column v-slot="props">
            <router-link
              class="button is-info is-light is-small is-outline is-rounded"
              :to="{
                name: 'bankaccountlisting',
                params: {
                  id: props.row.bankAccountListingID,
                  finInstBankName: `${account.financialInstitution.name} - ${account.name}`
                }
              }"
              >Details</router-link
            >
          </b-table-column>
        </b-table>
        <div style="padding-top:20px !important;">
          <router-link
            :to="{
              name: 'createBankAccountListing',
              params: {
                id: account.bankAccountID,
                finInstBankName: `${account.financialInstitution.name} - ${account.name}`
              }
            }"
            class="button is-primary is-rounded"
            >Add</router-link
          >&nbsp;
        </div>
      </div>
    </div>
  </PageContent>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import PageContent from '@/containers/PageContent'
import { ValidationError } from '@/services/errors'
import kms from '@/services/kms'
import EmptyList from '@/components/EmptyList'
import { notifyError, notifyMessage } from '@/services/notify'
import { bankDataProviderStore } from '@/services/BankDataProvider/store'

// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

import moment from 'moment'
const today = moment()

let DEFAULT_FORM_VALUES = {
  bankName: '',
  accountNumber: '',
  routingNumber: '',
  bankAccountTypeID: 0,
  bankDataProviderAccountID: '',
  bankGLAccountID: 0,
  interestIncomeGLAccountID: 0
}

export default {
  components: {
    EmptyList,
    PageContent,
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      defaultBankProviderId: 0,
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: true,
      confirmModal: false,
      selectedRow: null,
      financialInstitutionName: '',
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      formatFn: function(value) {
        return value != null ? value : ''
      },
      formatNumberFn: function(value) {
        return value != null ? value : 0
      }
    }
  },

  computed: {
    ...mapState({
      account: state => state.bankaccount.bankAccount,
      hoaId: state => state.user.selectedHoaId,
      bankAccountTypes: state => state.bankaccount.bankAccountTypes,
      assetAccounts: state => state.hoa.glAssetAccountsList,
      interestIncomeAccounts: state => state.hoa.glInterestIncomeAccountsList,
      financialInstitutionId: state => state.bankaccount.selectedFinancialInstitutionId,
      plaidInstitutionId: state => state.bankaccount.selectedPlaidInstitutionId,
      bankAccountListings: state => state.bankaccount.bankAccountListings
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },

    selectedRow(row) {
      this.$router.push({
        name: 'bankaccountlisting',
        params: { id: row.bankAccountListingID, finInstBankName: this.financialInstitutionName }
      })
    }
  },

  mounted() {
    this.reload()
  },

  methods: {
    ...mapActions('bankaccount', [
      'loadBankAccount',
      'loadBankAccountTypesList',
      'loadBankAccountListings'
    ]),
    ...mapActions('hoa', ['loadAssetGLAccountsList', 'loadInterestIncomeGLAccountsList']),

    onConfirm() {
      this.$emit('confirm')
    },
    onCancel() {
      this.$emit('cancel')
    },

    async reload() {
      this.loading = true
      try {
        this.formData = { ...DEFAULT_FORM_VALUES }
        if (this.$refs.form) {
          this.$refs.form.reset()
        }

        await this.getProvider()

        await this.loadBankAccount(this.$route.params.id)
        await this.loadAssetGLAccountsList()
        await this.loadInterestIncomeGLAccountsList()
        await this.loadBankAccountTypesList()
        await this.loadBankAccountListings({
          bankAccountID: this.$route.params.id,
          showUnreconciledOnly: false
        })

        console.debug('loadBankAccount=' + JSON.stringify(this.account))
        console.debug('bankaccounttypes=' + JSON.stringify(this.account))
        console.debug('bankaccountlistings=' + JSON.stringify(this.bankAccountListings))

        this.financialInstitutionName = this.$route.params.financialInstitution
      } catch (e) {
        notifyError(e)
      }

      this.loading = false
    },

    async getProvider() {
      await bankDataProviderStore
        .dispatch('getProvider', {
          providerName: 'Plaid',
          isDefault: true
        })
        .then(({ result }) => {
          if (result) {
            this.defaultBankProviderId = result.bankDataProviderID

            console.debug('this.defaultBankProviderId=' + this.defaultBankProviderId)
          }
        })
    },

    getFormPayload() {
      let finId =
        this.financialInstitutionId == null
          ? this.account.financialInstitutionID
          : this.financialInstitutionId
      console.debug('finid=' + finId)

      const payload = {
        bankAccountID: this.account.bankAccountID,
        hoaID: this.hoaId,
        bankDataProviderID: parseInt(this.defaultBankProviderId),
        name: this.account.name,
        bankAccountTypeID: this.account.bankAccountTypeID,
        accountNumber: this.account.accountNumber,
        routingNumber: this.account.routingNumber,
        bankGLAccountID: this.account.bankGLAccountID,
        bankDataProviderAccountID: this.account.bankDataProviderAccountID,
        financialInstitutionID: finId,
        interestIncomeGLAccountID: this.account.interestIncomeGLAccountID,
        modifiedDate: today.toDate(),
        closedDate: this.account.isClosed ? today.toDate() : null
      }
      return payload
    },

    async deleteBankAccount(id, bankName) {
      if (id <= 0) {
        notifyError('Unable to delete this bank account.')
        return
      }

      if (confirm(`Are you sure you want to delete '${bankName}'?`)) {
        try {
          this.loading = true
          const data = { bankAccountID: id, forceDelete: false }
          const path = `/BankAccounts/BankAccount/${id}?forceDelete=false`
          const results = await kms.delete(path, data)

          console.debug(JSON.stringify(results))

          if (results.recordID >= 0) {
            this.formComplete = true
            this.reload()
            this.loading = false
            this.$router.push('/bankAccounts')
            notifyMessage(`${bankName} was successfully deleted.`)
          } else {
            notifyError('There was a problem deleting your Linked Bank Account.')
          }
        } catch (e) {
          // If this is a validation error, get the details for each field
          if (e instanceof ValidationError) {
            this.$refs.form.setErrors(e.fields)
          } else {
            notifyError(e)
          }
        }
      } else {
        this.reload()
      }
    },

    async onFormSubmit() {
      try {
        this.loading = true
        console.debug(this.getFormPayload())

        const path = `/BankAccounts/BankAccount/UpsertBankAccountForClient`
        const results = await kms.post(path, this.getFormPayload())

        console.debug(JSON.stringify(results))

        if (results.bankAccountID >= 0 && results.name) {
          this.formComplete = true
          this.reload()
          this.loading = false
          notifyMessage(`${results.name} was successfully updated.`)
        } else {
          notifyError('There was a problem creating your Linked Bank Account.')
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError(e)
      }
      this.loading = false
    }
  },

  i18n: {
    messages: {
      en: { bankAccount: { detailsTitle: 'Bank Account Details' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em 0.5em 0 0;
  }
}

.details-menu {
  a {
    color: #838383;
    display: inline-block;
    padding: 0.5em 0;

    &:hover,
    &:active {
      color: inherit;
    }
    &.router-link-active {
      color: #ff7300; // #381;
      &::after {
        content: '→';
      }
    }
  }
}
</style>
