//
import authGuard from '@/router/auth-guard'
//
import Architectural from '@/pages/Management/units/tabs/Architectural/Requests'
import ArchitecturalDetails from '@/pages/Management/architectural/Details'
import ComingSoon from '@/components/ComingSoon'

export default [
  {
    path: '/properties',
    name: 'properties',
    component: () =>
      import(
        /* webpackChunkName: "units" */
        '@/pages/Management/units/UnitList/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/properties/:id/:ownerid/:previousownerid',
    component: () =>
      import(/* webpackChunkName: "units" */ '@/pages/Management/units/UnitDetails/index.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: 'overview',
        name: 'unit.overview',
        component: () =>
          import(/* webpackChunkName: "units" */ '@/pages/Management/units/tabs/Overview/index.vue')
      },
      {
        path: 'json',
        name: 'unit.json',
        component: () =>
          import(/* webpackChunkName: "units" */ '@/pages/Management/units/tabs/JSON.vue')
      },
      {
        path: 'emergencyContacts',
        name: 'unit.emergencyContacts',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Management/units/tabs/EmergencyContacts/List.vue'
          )
      },
      {
        path: 'ownerContacts',
        name: 'unit.ownerContacts',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Management/units/tabs/OwnerContacts/List.vue'
          )
      },
      {
        path: 'accountsReceivable',
        name: 'unit.accountsReceivable',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Management/units/tabs/Accounts/Activity.vue'
          )
      },
      {
        path: 'accountsReceivableWaiver/:id',
        name: 'unit.accountsReceivableWaiver',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Management/units/tabs/WaiverFeeRequests/List.vue'
          )
      },
      {
        path: 'collections',
        name: 'unit.collections',
        component: ComingSoon
      },
      {
        path: 'communication',
        name: 'unit.communication',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Management/units/tabs/Communication/Main.vue'
          )
      },
      {
        path: 'compliance',
        name: 'unit.compliance',
        component: () =>
          import(
            /* webpackChunkName: "unit.compliance" */ '@/pages/Management/units/tabs/Compliance/Main.vue'
          )
      },
      {
        path: 'architectural',
        name: 'unit.architectural',
        component: Architectural
      },
      {
        path: 'architectural/:id',
        name: 'unit.architectural.details',
        component: ArchitecturalDetails
      },
      {
        path: 'workOrders',
        name: 'unit.workOrders',
        component: () =>
          import(
            /* webpackChunkName: "unit.workOrders" */ '@/pages/Management/units/tabs/WorkOrders/Main.vue'
          )
      },
      {
        path: 'notes/:id',
        name: 'unit.notes',
        component: () =>
          import(
            /* webpackChunkName: "unit.notes" */ '@/pages/Management/units/tabs/Notes/Main.vue'
          )
      },
      {
        path: 'vehicles/:id',
        name: 'unit.vehicles',
        component: () =>
          import(
            /* webpackChunkName: "unit.vehicles" */ '@/pages/Management/units/tabs/Vehicles/List.vue'
          )
      },
      {
        path: 'leaseInformation',
        name: 'unit.leaseInformation',
        component: () =>
          import(
            /* webpackChunkName: "unit.leaseInformation" */ '@/pages/Management/units/tabs/Lease/Information.vue'
          )
      },
      {
        path: 'vehicles',
        name: 'unit.vehicles',
        component: ComingSoon
      },
      {
        path: 'priorOwners',
        name: 'unit.priorOwners',
        component: () =>
          import(
            /* webpackChunkName: "unit.leaseInformation" */ '@/pages/Management/units/tabs/PriorOwners/Main.vue'
          )
      },
      {
        path: 'insurance',
        name: 'unit.insurance',
        component: () =>
          import(
            /* webpackChunkName: "unit.insurance" */ '@/pages/Management/units/tabs/Insurance/Main.vue'
          )
      },
      {
        path: 'lateFeeSuspensions',
        name: 'unit.lateFeeSuspensions',
        component: () =>
          import(
            /* webpackChunkName: "unit.lateFeeSuspensions" */ '@/pages/Management/units/tabs/LateFeeSuspension/List.vue'
          )
      },
      {
        path: 'waiverRequests',
        name: 'unit.waiverRequests',
        component: () =>
          import(
            /* webpackChunkName: "unit.waiverRequests" */ '@/pages/Management/units/tabs/WaiverFeeRequests/List.vue'
          )
      },
      {
        path: 'mailingAddress',
        name: 'unit.mailingAddress',
        component: () =>
          import(
            /* webpackChunkName: "unit.mailingAddress" */ '@/pages/Management/units/tabs/MailingAddress/index.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'overview'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'overview'
      }
    ]
  }
]
