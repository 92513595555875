import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/_guides/ModernTable',
    name: '_guide.ModernTable',
    component: () =>
      import(
        /* webpackChunkName: "_guides.ModernTable" */ '@/components/tables/Modern/guide/Examples.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/ValidInput',
    name: '_guide.ValidInput',
    component: () =>
      import(
        /* webpackChunkName: "_guides.ValidInput" */ '@/components/inputs/ValidInput/guide/Examples.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/DatePicker',
    name: '_guide.DatePicker',
    component: () =>
      import(
        /* webpackChunkName: "_guides.DatePicker" */ '@/components/inputs/DatePicker/examples/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/TimePicker',
    name: '_guide.TimePicker',
    component: () =>
      import(
        /* webpackChunkName: "_guides/TimePicker" */ '@/components/inputs/TimePicker/examples/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/DateTimePicker',
    name: '_guide.DateTimePicker',
    component: () =>
      import(
        /* webpackChunkName: "_guides.DateTimePicker" */ '@/components/inputs/DateTimePicker/examples/1/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/Modal',
    name: '_guide.Modal',
    component: () =>
      import(/* webpackChunkName: "_guides.Modal" */ '@/components/Modal/examples/List.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/MonthAndDay',
    name: '_guide.MonthAndDay',
    component: () =>
      import(
        /* webpackChunkName: "_guides.MonthAndDay" */ '@/components/inputs/MonthAndDay/examples/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/Phone',
    name: '_guide.Phone',
    component: () =>
      import(
        /* webpackChunkName: "_guides.Phone" */ '@/components/inputs/Phone/_guide/Examples.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/PDFUploader',
    name: '_guide.PDFUploader',
    component: () =>
      import(
        /* webpackChunkName: "_guides.PDFUploader" */ '@/components/Fields/PDFUploader/examples/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/PhotoUploader',
    name: '_guide.PhotoUploader',
    component: () =>
      import(
        /* webpackChunkName: "_guides.PhotoUploader" */ '@/components/Fields/PhotoUploader/examples/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/DocViewer',
    name: '_guide.DocViewer',
    component: () =>
      import(
        /* webpackChunkName: "_guides.DocViewer" */ '@/components/DocViewer/examples/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/PleasantCalendar',
    name: '_guide.PleasantCalendar',
    component: () =>
      import(
        /* webpackChunkName: "_guides.PleasantCalendar" */ '@/components/Calendars/Pleasant/examples/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/Lists/Nested',
    name: '_guide.Lists.Nested',
    component: () =>
      import(/* webpackChunkName: "_guides.Lists.Nested" */ '@/components/Lists/Nested/index.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/_guides/Sortable/List',
    name: '_guide.Sortable.List',
    component: () =>
      import(
        /* webpackChunkName: "_guides.Sortable.List" */ '@/components/Sortable/List/_examples/index.vue'
      ),
    beforeEnter: authGuard
  }
]
