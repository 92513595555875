import authGuard from '@/router/auth-guard'
export default [
  {
    path: '/communityCenter',
    name: 'communityCenter',
    component: () =>
      import(
        /* webpackChunkName: "communityCenter" */

        '@/pages/Management/communityCenter/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'surveyList',
        name: 'communityCenter.surveyList',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.surveys" */

            '@/pages/Management/communityCenter/tabs/SurveyList/List.vue'
          )
      },
      {
        path: 'calendar',
        name: 'communityCenter.calendar',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.calendar" */

            '@/pages/Management/communityCenter/tabs/calendar/Main/index.vue'
          )
      },
      {
        path: '/frequentlyAskedQuestions',
        name: 'communityCenter.frequentlyAskedQuestions',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.frequentlyAskedQuestions" */

            '@/pages/Management/communityCenter/tabs/frequentlyAskedQuestions/List.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: 'suggestions',
        name: 'communityCenter.suggestions',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.suggestions" */

            '@/pages/Management/communityCenter/tabs/suggestions/List.vue'
          )
      },
      {
        path: 'documentCenter',
        name: 'communityCenter.documentCenter',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.documentCenter" */ '@/pages/Management/communityCenter/tabs/documentCenter/List.vue'
          )
      },
      {
        path: '/hoaContacts',
        name: 'communityCenter.hoaContacts',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.hoaContacts" */ '@/pages/Management/communityCenter/tabs/hoaContacts/List.vue'
          )
      },
      {
        path: '/marquees',
        name: 'communityCenter.marquees',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.marquees" */ '@/pages/Management/communityCenter/tabs/marqueeAnnouncements/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'surveyList'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'surveyList'
      }
    ]
  },
  {
    path: '/surveySections/:id/:name',
    name: 'surveySections',
    component: () =>
      import(
        /* webpackChunkName: "surveySections" */ '@/pages/Management/communityCenter/tabs/sectionList/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/surveyResults/:id/:name',
    name: 'surveyResults',
    component: () =>
      import(
        /* webpackChunkName: "surveyResults" */ '@/pages/Management/communityCenter/tabs/answerResults/List.vue'
      ),
    beforeEnter: authGuard
  }
]
