/*
  '14': {
    name: 'Vendors',
    includes: { 
      '15': 'Work Order Flows', 
      '72': 'Management' 
    }
  }
*/

import { authFeatureGuard } from '@/router/auth-feature-guard'

export default [
  {
    path: '/vendors',
    name: 'vendors',
    component: () =>
      import(
        /* webpackChunkName: "vendors" */
        '@/pages/Management/vendors/List.vue'
      ),
    beforeEnter: authFeatureGuard(14)
  },
  {
    path: '/vendors/:id',
    component: () =>
      import(
        /* webpackChunkName: "vendors" */ '@/pages/Management/vendors/VendorDetails/index.vue'
      ),
    beforeEnter: authFeatureGuard(14),
    children: [
      {
        path: 'vendorContacts',
        name: 'vendor.vendorContacts',
        component: () =>
          import(
            /* webpackChunkName: "vendors.vendorContacts" */ '@/pages/Management/vendors/tabs/Contacts/List.vue'
          )
      },
      {
        path: 'vendorInsuranceCoverages',
        name: 'vendor.vendorInsuranceCoverages',
        component: () =>
          import(
            /* webpackChunkName: "vendors.vendorInsuranceCoverages" */ '@/pages/Management/vendors/tabs/InsuranceCoverages/List.vue'
          )
      },
      {
        path: 'vendorLicenses',
        name: 'vendor.vendorLicenses',
        component: () =>
          import(
            /* webpackChunkName: "vendors.vendorLicenses" */ '@/pages/Management/vendors/tabs/Licenses/List.vue'
          )
      },
      {
        path: 'vendorServiceTypes',
        name: 'vendor.vendorServiceTypes',
        component: () =>
          import(
            /* webpackChunkName: "vendors.vendorServiceTypes" */ '@/pages/Management/vendors/tabs/ServiceTypes/List.vue'
          )
      },
      {
        path: 'vendorWorkOrders',
        name: 'vendor.vendorWorkOrders',
        component: () =>
          import(
            /* webpackChunkName: "vendors.vendorWorkOrders" */ '@/pages/Management/vendors/tabs/WorkOrders/List.vue'
          )
      },
      {
        path: 'vendorInvoices',
        name: 'vendor.vendorInvoices',
        component: () =>
          import(
            /* webpackChunkName: "vendors.vendorInvoices" */ '@/pages/Management/vendors/tabs/Invoices/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'vendorContacts'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'vendorContacts'
      }
    ]
  }
]
