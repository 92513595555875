import Vue from 'vue'
import VueRouter from 'vue-router'
import scrollBehavior from '../common/scrollBehavior'
import { afterEach } from '../common/afterEach'
import routes from './routes'

Vue.use(VueRouter)

const { NODE_ENV, BASE_URL } = process.env

// In dev server, each app is proxied from a sub-folder path
const base = NODE_ENV === 'development' ? '/management/' : BASE_URL

const router = new VueRouter({
  mode: 'history',
  base,
  routes,

  scrollBehavior
})

const tabPaths = Object.freeze({
  '/accountSettings/': true,
  '/userSettings/': true,
  '/properties/': true,
  '/vendors/': true,
  '/vendorRequests/': true,
  '/communityCalendar/': true,
  '/communityCenter/': true,
  '/suggestionTabs/': true,
  '/invoices/': true,
  '/workorder/': true,
  '/workorderrequest/': true,
  '/violation/': true,
  '/architectural/': true,
  '/deliveryitem/': true,
  '/actionitem/': true,
  '/escrow/': true,
  '/evoting/': true,
  '/boardMeetings/': true,
  '/boardMeetingDetails/': true
})

router.beforeEach((to, from, next) => {
  /*
    this adds #tab so that the active tab of DetailsMenu is highlighted 
  */
  const fullPath = to.fullPath
  for (const tabPath in tabPaths) {
    if (fullPath.indexOf(tabPath) === 0 && to.hash !== '#tab') {
      next({
        path: fullPath + '#tab'
      })
      return
    }
  }

  next()
})

router.afterEach((to, from) => {
  afterEach({ to, from })
})

export default router
