<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 35 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      :stroke="stroke"
      stroke-width="2"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
      :style="{ transform: 'translate(2px,0px) scale(1.3)' }"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
      ></path>
    </g>
  </svg>
</template>

<script>
const height = 32
const width = 32

export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  },
  data() {
    return {
      height,
      width
    }
  }
}
</script>
