<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 48 42"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-28.000000, -729.000000)" fill="#DAE0E9">
        <g transform="translate(28.000000, 729.000000)">
          <path
            d="M38.5 30.25v-16.1L28 6.8l-10.5 7.35v6.45h-3v-7.95L28 3l13.5 9.65v17.6ZM28 6.8ZM29 17h2v-2h-2Zm-4 0h2v-2h-2Zm4 4h2v-2h-2Zm-4 0h2v-2h-2Zm3.3 23.9-15.55-4.45v2.85H2V23.6h15.45l12.75 4.8q1.35.5 2.275 1.625.925 1.125.925 3.225h5.7q2.1 0 3.5 1.5T44 38.8v1.3ZM5 40.3h4.7V26.6H5Zm23.1 1.5 12.8-3.9q-.3-.95-.75-1.3-.45-.35-1.05-.35H28.75q-1.5 0-2.775-.2t-2.425-.55l-4.05-1.25 1.1-2.9 3.65 1.2q1.25.4 2.375.55 1.125.15 3.575.15 0-.6-.225-1.175-.225-.575-.775-.825L16.95 26.6h-4.2v10.7ZM9.7 33.45Zm20.5-.2Zm-20.5.2Zm3.05 0Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
