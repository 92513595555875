import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/communications',
    name: 'communications',
    component: () =>
      import(/* webpackChunkName: "escrow" */ '@/pages/Management/mailingCommunications/List.vue'),
    beforeEnter: authGuard
  }
]
