var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.on)?_c('div',{style:({
    background: 'rgba(200,200,200,.3)',

    position: 'fixed',
    zIndex: 10000000,
    top: 0,
    left: 0,

    // height: '100vh',
    width: '100vw'
  }),attrs:{"overlays-container":""}},[(typeof _vm.component === 'object')?_c(_vm.component,_vm._b({tag:"component"},'component',_vm.props,false)):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }