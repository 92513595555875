/*
  // Amenities
  '25': {
    name: 'Amenity Management',
    includes: {
      '73': 'Creation',
      '74': 'Rental Workflows',
      '75': 'Usage Calendar'
    }
  },
*/

import { authFeatureGuard } from '@/router/auth-feature-guard'

export default [
  {
    path: '/amenities',
    name: 'amenities',
    component: () => import('@/pages/Shared/amenities/Main/index.vue'),
    beforeEnter: authFeatureGuard(25)
  },
  {
    path: '/amenity/:id',
    name: 'amenity',
    component: () => import('@/pages/Shared/amenities/Details/index.vue'),
    beforeEnter: authFeatureGuard(25)
  },
  {
    path: '/amenityconversations/:id',
    name: 'amenityconversations',
    component: () => import('@/pages/Shared/amenities/Main/conversations/index.vue'),
    beforeEnter: authFeatureGuard(25)
  }
]
