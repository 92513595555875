import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/evoting',
    name: 'evoting',
    component: () =>
      import(/* webpackChunkName: "evoting" */ '@/pages/Management/evoting/List.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/evotingTabs/:id',
    name: 'evotingTabs',
    component: () =>
      import(/* webpackChunkName: "evoting" */ '@/pages/Management/evoting/details/index.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: 'evotingDetails',
        name: 'evoting.evotingDetails',
        component: () =>
          import(
            /* webpackChunkName: "evoting" */ '@/pages/Management/evoting/details/tabs/evotingDetails/index.vue'
          )
      },
      {
        path: 'evotingResponses',
        name: 'evoting.evotingResponses',
        component: () =>
          import(
            /* webpackChunkName: "evoting" */ '@/pages/Management/evoting/details/tabs/evotingResponses/index.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'evotingDetails'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'evotingDetails'
      }
    ]
  }
]
