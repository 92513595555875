import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/actionItems',
    name: 'actionItems',
    component: () =>
      import(
        /* webpackChunkName: "actionItems" */

        '@/pages/Management/actionItems/List/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/actionItemTabs/:id/:viewonly',
    name: 'actionItemTabs',
    component: () =>
      import(
        /* webpackChunkName: "actionItemTabs" */ '@/pages/Management/actionItems/details/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'actionItemDetails',
        name: 'actionitem.actionItemDetails',
        component: () =>
          import(
            /* webpackChunkName: "actionitem.actionItemDetails" */

            '@/pages/Management/actionItems/details/tabs/actionItemDetails/index.vue'
          )
      },
      {
        path: 'actionItemNotes',
        name: 'actionitem.actionItemNotes',
        component: () =>
          import(
            /* webpackChunkName: "actionitem.actionItemNotes" */

            '@/pages/Management/actionItems/details/tabs/notes/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'actionItemDetails'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'actionItemDetails'
      }
    ]
  }
]
