/*
  import { bankDataProviderStore } from '@/services/BankDataProvider/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { result } = await bankDataProviderStore.dispatch ('getProvider', {
      garageID
    });
  */
  async getProvider({}, { providerName, isDefault }) {
    try {
      console.debug('in getProvider...')
      const result = await kms.get(
        `/CheckbookReconciliation/BankDataProvider/GetDefaultByProviderName`,
        {
          params: {
            providerName,
            isDefault
          }
        }
      )

      if (isDebug == true) console.debug('getProvider=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the default band provider.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const bankDataProviderStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
