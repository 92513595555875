/*
  '64': {
    name: 'Violations',
    includes: { '65': 'Reporting Workflows', '66': 'Escalation Fines' }
  }
*/

import { authFeatureGuard } from '@/router/auth-feature-guard'

export default [
  {
    path: '/violations',
    name: 'violations',
    component: () =>
      import(/* webpackChunkName: "violations" */ '@/pages/Management/violations/Main/index.vue'),
    beforeEnter: authFeatureGuard(64)
  },
  {
    path: '/violationtabs/:id',
    name: 'violationTabs',
    component: () =>
      import(/* webpackChunkName: "violations" */ '@/pages/Shared/violations/Details/index.vue'),
    beforeEnter: authFeatureGuard(64),
    children: [
      {
        path: 'violationDetails',
        name: 'violation.violationDetails',
        component: () =>
          import(
            /* webpackChunkName: "violations" */ '@/pages/Shared/violations/Details/tabs/details/index.vue'
          )
      },
      {
        path: 'violationConversations',
        name: 'violation.violationConversations',
        component: () =>
          import(
            /* webpackChunkName: "violations" */ '@/pages/Shared/violations/Details/tabs/conversations/List.vue'
          )
      },
      {
        path: 'violationHistory',
        name: 'violation.violationHistory',
        component: () =>
          import(
            /* webpackChunkName: "violations" */ '@/pages/Shared/violations/Details/tabs/history/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'violationDetails'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'violationDetails'
      }
    ]
  }
]
