<template>
  <svg
    style="margin-right: 4px;"
    xmlns="http://www.w3.org/2000/svg"
    height="28"
    width="28"
    viewBox="-96 96 960 960"
  >
    <g fill="#DAE0E9">
      <path
        d="M185.087 990.131q-32.507 0-55.862-23.356-23.356-23.355-23.356-55.862V301.087q0-32.74 23.356-56.262 23.355-23.521 55.862-23.521H245v-60h74.609v60h320.782v-60H715v60h59.913q32.74 0 56.262 23.521 23.521 23.522 23.521 56.262v609.826q0 32.507-23.521 55.862-23.522 23.356-56.262 23.356H185.087Zm0-79.218h589.826V486H185.087v424.913Zm0-484.913h589.826V301.087H185.087V426Zm0 0V301.087 426ZM280 639.957v-66.783h400v66.783H280Zm0 178.304v-66.218h279v66.218H280Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
