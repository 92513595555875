<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g transform="translate(-9.000000, -59.000000)" :stroke="stroke" stroke-width="2">
        <g transform="translate(13.000000, 15.000000)">
          <g transform="translate(2.000000, 48.000000)">
            <path
              d="M19,14 C17.2690653,18.050098 13.0202088,20.49195 9,20 C4.2059588,19.3409784 0.72304584,15.889506 0,11 C-0.525801368,7.06822072 1.86163567,2.7817525 6,1"
            ></path>
            <path
              d="M20,10 C20,7.3478351 18.9464316,4.80429597 17,3 C15.195704,1.0535684 12.6521649,0 10,0 L10,10 L20,10 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
