import authGuard from '@/router/auth-guard'

import { details } from './details'

export default [
  {
    path: '/boardMeetings',
    name: 'boardMeetings',
    component: () =>
      import(
        /* webpackChunkName: "actionItems" */ '@/pages/Management/boardMeetings/Main/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'list',
        name: 'boardMeetings.list',
        //name: 'boardMeetingsList',
        component: () =>
          import(
            /* webpackChunkName: "boardMeetings.list" */
            '@/pages/Management/boardMeetings/List/index.vue'
          )
      },
      {
        path: 'locations',
        name: 'boardMeetings.locations',
        //name: 'boardMeetingsLocations',

        component: () =>
          import(
            /* webpackChunkName: "boardMeetings.locations" */
            '@/pages/Management/boardMeetings/Locations/index.vue'
          )
      },
      {
        // If no tab is specifed, go to the meetings list
        path: '',
        redirect: 'list'
      },
      {
        // If path doesn't match, go to meetings list
        path: '*',
        redirect: 'list'
      }
    ]
  },
  ...details
]
