<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g transform="translate(-9.000000, -259.000000)" :stroke="stroke" stroke-width="2">
        <g transform="translate(13.000000, 15.000000)">
          <g transform="translate(3.000000, 250.000000)">
            <path
              d="M18.0952381,9.11095238 L18.0952381,9.95238095 C18.0929471,13.9592872 15.4554088,17.4874308 11.6129379,18.6235294 C7.77046697,19.7596281 3.63840369,18.2330443 1.45753079,14.8716314 C-0.723342102,11.5102186 -0.433504502,7.11472098 2.16986713,4.06877956 C4.77323876,1.02283814 9.06997811,0.0520466857 12.73,1.68285714"
            ></path>
            <polyline points="19 1.80952381 9.04761905 11.7619048 6.33333333 9.04761905"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
