/*
  import { sidebarMenuItems } from '@/utilities/sidebarMenuItems/Management'

  sidebarMenuItems ({
    hoaId
  })
*/

/*
  Feature List found at:
    [get] /Subscriptions/Feature/List { hoaID }
    
  Maybe partial list can be found at:
    src/utilities/Subscriptions/Features.txt
*/

/*
  toggle to turn the filter off
*/
const filter = true

import auth from '@/services/_auth'

import HouseIcon from '@/components/icons/House'
import WorkOrderIcon from '@/components/icons/WorkOrder'
import ChartIcon from '@/components/icons/Chart'
import ServicesIcon from '@/components/icons/Services'
import ViolationsIcon from '@/components/icons/Violations'
import Amenity2Icon from '@/components/icons/Amenity2'
import ManageAccountsIcon from '@/components/icons/ManageAccounts'
import DocumentIcon from '@/components/icons/Document'
import VendorsIcon from '@/components/icons/Vendors'
import SurveyIcon from '@/components/icons/Survey'
import MailingIcon from '@/components/icons/Mailing'
import ActionItemIcon from '@/components/icons/ActionItems'
import EscrowIcon from '@/components/icons/Escrow'
import EvotingIcon from '@/components/icons/Evoting'
import CustomerSupportIcon from '@/components/icons/CustomerSupport'
import BoardMeetingsIcon from '@/components/icons/BoardMeeting'
import CommunicationsIcon from '@/components/icons/Communication'

import store from '@/store'

const apps = {
  amenities: {
    to: '/amenities',
    iconC: WorkOrderIcon,
    label: 'Amenities'
  },
  architecturalRequests: {
    to: '/architecturalRequests',
    iconC: ServicesIcon,
    label: 'Architectural Requests'
  },
  properties: {
    to: '/properties',
    iconC: HouseIcon,
    label: 'Properties'
  },
  accounting: {
    to: '/accountingDashboard',
    iconC: ChartIcon,
    label: 'Accounting'
  },
  violations: {
    to: '/violations',
    iconC: ViolationsIcon,
    label: 'Violations'
  },
  workOrders: {
    to: '/workOrders',
    iconC: Amenity2Icon,
    label: 'Work Orders'
  }
}

export function sidebarMenuItems({ hoaId }) {
  const subscribes = filter
    ? store.getters['subscription/hasHOAFeature']
    : function() {
        return true
      }
  const allowed = function(permission) {
    return auth.hasPermission(hoaId, permission)
  }

  const items = [
    ...[subscribes(57) ? apps.properties : {}],
    ...[subscribes(25) ? apps.amenities : {}],
    ...[subscribes(31) ? apps.accounting : {}],
    ...[subscribes(69) && allowed('ARCH_VIEW') ? apps.architecturalRequests : {}],
    ...[subscribes(64) && allowed('VIO_VIEW') ? apps.violations : {}],
    ...[subscribes(15) ? apps.workOrders : {}],

    {
      // feature id =
      to: '/bankAccounts',
      iconC: ManageAccountsIcon,
      label: 'Bank Accounts'
    },

    ...(subscribes(31)
      ? [
          {
            to: '/reportsall',
            iconC: DocumentIcon,
            label: 'Reports'
          }
        ]
      : []),
    ...(subscribes(14)
      ? [
          {
            to: '/vendors',
            iconC: VendorsIcon,
            label: 'Vendors'
          }
        ]
      : []),
    ...(subscribes(58)
      ? [
          {
            to: '/communityCenter',
            iconC: SurveyIcon,
            label: 'Community Info'
          }
        ]
      : []),

    {
      // feature id =
      to: '/mailings',
      iconC: MailingIcon,
      label: 'Mailing Management'
    },
    {
      // feature id =
      to: '/actionItems',
      iconC: ActionItemIcon,
      label: 'Board Action Items'
    },
    {
      // feature id =
      to: '/boardMeetings',
      iconC: BoardMeetingsIcon,
      label: 'Board Meetings'
    },

    ...(subscribes(53)
      ? [
          {
            to: '/escrow',
            iconC: EscrowIcon,
            label: 'Escrow'
          }
        ]
      : []),
    ...(subscribes(53)
      ? [
          {
            to: '/evoting',
            iconC: EvotingIcon,
            label: 'eVoting'
          }
        ]
      : []),
    {
      to: '/communications',
      iconC: CommunicationsIcon,
      label: 'Communications'
    },
    {
      to: '/customerSupport',
      iconC: CustomerSupportIcon,
      label: 'Customer Support'
    }
  ]

  return items
}
