/*
  posibilities:
    [ ] place for designating the geographic bounds of the HOA?
    [ ] toggling has garages?  these section is off by default?
    [ ] toggling has parking spaces?  this section is off by default?
*/

import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/accountSettings',
    // name: 'accountSettings',
    component: () =>
      import(
        /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/Main.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'accessManagement',
        name: 'accountSettings.accessManagement',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/AccessManagement/index.vue'
          )
      },
      {
        path: 'accessManagement/users',
        name: 'accountSettings.accessManagement.users',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/AccessManagement/index.vue'
          )
      },
      {
        path: 'accessManagement/groups',
        name: 'accountSettings.accessManagement.groups',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/AccessManagement/index.vue'
          )
      },
      {
        path: 'committees',
        name: 'accountSettings.committees',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/Committees/Main.vue'
          )
      },
      {
        path: 'generalSettings',
        name: 'accountSettings.generalSettings',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/pages/GeneralSettings.vue'
          )
      },
      {
        path: 'apSettings',
        name: 'accountSettings.apSettings',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/pages/apsetting/APSettings.vue'
          )
      },
      {
        path: 'reserveFunding',
        name: 'accountSettings.reserveFunding',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/ReserveFunding/Main.vue'
          )
      },
      {
        path: 'subscription',
        name: 'accountSettings.subscription',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/subscription/Subscription/Leaf.vue'
          )
      },
      {
        path: 'supportAccess',
        name: 'accountSettings.supportAccess',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/pages/SupportAccess.vue'
          )
      },
      {
        path: 'garages',
        name: 'accountSettings.garages',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/pages/garages/GarageList.vue'
          )
      },
      {
        path: 'parkingspaces',
        name: 'accountSettings.parkingspaces',
        component: () =>
          import(
            /* webpackChunkName: "accountsettings" */ '@/pages/Management/accountSettings/pages/parkingspaces/ParkingList.vue'
          )
      },
      //
      //
      {
        // tab not specifed -> generalSettings
        path: '',
        redirect: 'generalSettings'
      },
      {
        // tab path not found -> general-settings
        path: '*',
        redirect: 'generalSettings'
      }
    ]
  }
]
