/*
  import { authFeatureGuard } from '@/router/auth-feature-guard'
  
  Example:
    beforeEnter: authFeatureGuard(25)
*/

import store from '../store'

const debug = true

export function authFeatureGuard(featureId) {
  return (to, from, next) => {
    const authUser = store.getters['user/authUser']
    const hasHOAFeature = store.getters['subscription/hasHOAFeature'](featureId)

    if (authUser) {
      if (hasHOAFeature) {
        if (debug) console.info('subscription has feature id:', featureId)
        next()
        return
      }

      next({
        path: '/subscriptionRequired',
        query: {
          featureRoute: to.fullPath
        }
      })
      return
    }

    next({
      path: '/signin',
      query: {
        nextUrl: to.fullPath
      }
    })
  }
}
