import authGuard from '@/router/auth-guard'
import commonRoutes from '@/router/common/routes'
//
import account from './account'
import accounts from './accounts'
import actionItems from './actionItems'
import architecturalRequests from './architecturalRequests'
import accountSettings from './accountSettings'
import amenities from './amenities'
import boardMeetings from './boardMeetings'
import bankAccounts from './bankAccounts'
import communityCenter from './communityCenter'
import customerSupport from './customerSupport'
import escrow from './escrow'
import evoting from './evoting'
import mailings from './mailings'
import properties from './properties'
import suggestions from './suggestionTabs'
import userSettings from './userSettings'
import vendorRequests from './vendorRequests'
import vendors from './vendors'
import violations from './violations'
import workorders from './workorders'
import frequentlyAskedQuestions from './frequentlyAskedQuestions'
import guides from './_guides'
import communications from './communications'

const routes = [
  // maybe these should only be accessible in dev mode...
  ...guides,

  // Routes shared across apps
  ...commonRoutes,

  // Protected Routes
  // User Login Required (w/authGuard)
  {
    path: '/',
    name: 'home',
    component: () =>
      import(
        /* webpackChunkName: "managementDashboard" */ '@/pages/Management/dashboards/MainDashboard.vue'
      ),
    beforeEnter: authGuard
  },
  ...account,
  ...accounts,
  ...accountSettings,
  ...amenities,
  ...boardMeetings,
  ...userSettings,
  ...vendors,
  ...vendorRequests,
  ...communityCenter,
  ...mailings,
  ...actionItems,
  ...suggestions,
  ...escrow,
  ...evoting,
  ...customerSupport,
  ...frequentlyAskedQuestions,
  ...communications,

  {
    path: '/subscriptionRequired',
    name: 'subscriptionRequired',
    component: () =>
      import(/* webpackChunkName: "accountingDashboard" */ '@/pages/Shared/subscriptionRequired'),
    beforeEnter: authGuard
  },

  {
    path: '/accountingDashboard',
    name: 'accountingDashboard',
    component: () =>
      import(
        /* webpackChunkName: "accountingDashboard" */ '@/pages/Management/dashboards/AccountingDashboard.vue'
      ),
    beforeEnter: authGuard
  },

  {
    path: '/accountingDashboard',
    name: 'accountingDashboard',
    component: () =>
      import(
        /* webpackChunkName: "accountingDashboard" */ '@/pages/Management/dashboards/AccountingDashboard.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/mainDashboard',
    name: 'mainDashboard',
    component: () =>
      import(
        /* webpackChunkName: "mainDashboard" */ '@/pages/Management/dashboards/MainDashboard.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/accountsList',
    name: 'accountsList',
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ '@/pages/Management/accounts/generalledger/GeneralLedgerAccountsList.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () =>
      import(/* webpackChunkName: "notifications" */ '@/pages/Shared/notifications/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/alerts/:id',
    // name: 'alerts/:id',
    component: () =>
      import(/* webpackChunkName: "notifications" */ '@/pages/Shared/notifications/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/conversations',
    name: 'conversations',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/newconversation',
    name: 'newconversation',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/newConversation/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/conversationdetail/:id',
    name: 'conversationdetail',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/detail/index.vue'
      ),
    beforeEnter: authGuard
  },

  ...architecturalRequests,
  ...bankAccounts,

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/pages/Management/dashboards/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/invoice/create',
    name: 'invoiceCreate',
    component: () =>
      import(/* webpackChunkName: "invoice" */ '@/pages/Management/accounts/InvoiceCreate.vue'),
    beforeEnter: authGuard
  },

  ...properties,

  {
    path: '/userSettings',
    name: 'usersettings',
    component: () =>
      import(
        /* webpackChunkName: "usersettings" */ '@/pages/Management/accounts/userSettings/Main.vue'
      ),
    beforeEnter: authGuard
  },

  ...violations,
  ...workorders,
  ...vendors,

  {
    path: '*',
    name: 'notFound',
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/Management/NotFound')
  }
]

export default routes
