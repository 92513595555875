import authGuard from '@/router/auth-guard'
export default [
  {
    path: '/suggestionTabs/:id',
    name: 'suggestionTabs',
    component: () =>
      import(
        /* webpackChunkName: "suggestiontabs" */
        '@/pages/Management/communityCenter/tabs/suggestions/tabs/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'suggestionDetail',
        name: 'suggestionTabs.suggestionDetail',
        component: () =>
          import(
            /* webpackChunkName: "suggestionTabs.suggestionDetail" */
            '@/pages/Management/communityCenter/tabs/suggestions/tabs/detail/index.vue'
          )
      },
      {
        path: 'suggestionConversations',
        name: 'suggestionTabs.suggestionConversations',
        component: () =>
          import(
            /* webpackChunkName: "suggestionTabs.suggestionConversations" */
            '@/pages/Management/communityCenter/tabs/suggestions/tabs/conversations/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'suggestionDetail'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'suggestionDetail'
      }
    ]
  }
]
