import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/mailings',
    name: 'mailings',
    component: () =>
      import(/* webpackChunkName: "mailings" */ '@/pages/Management/mailingManagement/List.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/maildeliveryitems/:id/:mailingid/:title',
    name: 'maildeliveryitems',
    component: () =>
      import(
        /* webpackChunkName: "mailings" */ '@/pages/Management/mailingManagement/deliveryItems/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/detailitemtabs/:id/:mailingid/:title',
    name: 'detailitemtabs',
    component: () =>
      import(
        /* webpackChunkName: "mailings" */ '@/pages/Management/mailingManagement/details/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'itemDetails',
        name: 'deliveryitem.itemDetails',
        component: () =>
          import(
            /* webpackChunkName: "mailings" */ '@/pages/Management/mailingManagement/details/tabs/deliveryItemDetails/index.vue'
          )
      },
      {
        path: 'itemEvents',
        name: 'deliveryitem.itemEvents',
        component: () =>
          import(
            /* webpackChunkName: "mailings" */ '@/pages/Management/mailingManagement/details/tabs/events/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'itemDetails'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'itemDetails'
      }
    ]
  }
]
