import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/bankAccounts',
    name: 'bankAccounts',
    component: () =>
      import(/* webpackChunkName: "bankAccounts" */ '@/pages/Management/plaid/List/Main.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/bankAccounts/:id',
    name: 'bankAccount',
    component: () =>
      import(
        /* webpackChunkName: "bankAccounts" */ '@/pages/Management/plaid/FinancialAccountDetails/index.vue'
      ),
    beforeEnter: authGuard
  }
]
