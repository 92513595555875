/*
  '14': {
    name: 'Vendors',
    includes: { 
      '15': 'Work Order Flows', 
      '72': 'Management' 
    }
  }
*/

import { authFeatureGuard } from '@/router/auth-feature-guard'

export default [
  {
    path: '/workOrders',
    name: 'workOrders',
    component: () =>
      import(/* webpackChunkName: "workOrders" */ '@/pages/Management/workorder/list/index.vue'),
    beforeEnter: authFeatureGuard(15)
  },
  {
    path: '/workordertabs/:id',
    name: 'workOrderTabs',
    component: () =>
      import(/* webpackChunkName: "workOrders" */ '@/pages/Management/workorder/details/index.vue'),
    beforeEnter: authFeatureGuard(15),
    children: [
      {
        path: 'workorderDetails',
        name: 'workorder.workorderDetails',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Management/workorder/details/tabs/workOrderDetails/index.vue'
          )
      },
      {
        path: 'workorderDocuments',
        name: 'workorder.workorderDocuments',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Management/workorder/details/tabs/documents/List.vue'
          )
      },
      {
        path: 'workorderNotes',
        name: 'workorder.workorderNotes',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Management/workorder/details/tabs/notes/List.vue'
          )
      },
      {
        path: 'workorderPhotos',
        name: 'workorder.workorderPhotos',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Management/workorder/details/tabs/photos/List.vue'
          )
      },
      {
        path: 'workorderConversations',
        name: 'workorder.workorderConversations',
        component: () =>
          import(
            /* webpackChunkName: "workOrders" */ '@/pages/Management/workorder/details/tabs/conversations/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'workOrderDetails'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'workOrderDetails'
      }
    ]
  },
  {
    path: '/workorderpromotetabs/:id',
    name: 'workorderpromotetabs',
    component: () =>
      import(
        /* webpackChunkName: "workOrderRequest" */ '@/pages/Management/workorder/request/index.vue'
      ),
    beforeEnter: authFeatureGuard(15),
    children: [
      {
        path: 'workorderRequestPromote',
        name: 'workorderrequest.workorderRequestPromote',
        component: () =>
          import(
            /* webpackChunkName: "workOrderRequest" */ '@/pages/Management/workorder/request/tabs/details/index.vue'
          )
      },
      {
        path: 'workorderRequestConversation',
        name: 'workorderrequest.workorderRequestConversation',
        component: () =>
          import(
            /* webpackChunkName: "workOrderRequest" */ '@/pages/Management/workorder/request/tabs/conversations/List.vue'
          )
      }
    ]
  }
]
