<template>
  <PageContent :title="$t('comingSoon.title')">
    <json-box :value="value" v-if="value"></json-box>
  </PageContent>
</template>

<script>
import JsonBox from '@/components/JsonBox'
import PageContent from '@/containers/PageContent'

// import { mapState } from 'vuex'
export default {
  props: ['value'],
  components: { JsonBox, PageContent },
  computed: {
    // ...mapState({
    //   unit: state => state.hoa.unit
    // })
  },
  i18n: {
    messages: {
      en: { comingSoon: { title: 'Coming Soon' } }
    }
  }
}
</script>
