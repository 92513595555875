<template>
  <transition name="fade" appear>
    <div :class="'app-container ' + theme">
      <Sidebar
        v-if="loggedIn && $route.path !== '/promptmultifactor'"
        :menu-items="sidebarMenuItems"
      />

      <div>
        <!-- condensed sidebar for the sign in experience -->
        <DesktopSideBar v-if="!loggedIn" :menu-items="[]" />
      </div>

      <AppContent>
        <top-nav
          v-if="loggedIn && $route.path !== '/promptmultifactor'"
          :sidebar-items="sidebarMenuItems"
        />

        <Page>
          <transition class="flex-grow-2" name="fade" mode="out-in" appear>
            <router-view />
          </transition>
        </Page>
      </AppContent>

      <Overlays />

      <div tabindex="0" aria-label="end of page"></div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Page from '@/containers/Page'
import AppContent from '@/containers/AppContent'

// Components
import Sidebar from '@/components/Sidebar'
import TopNav from '@/components/navs/Top/index'
import DesktopSideBar from '@/components/Sidebar/components/Desktop'
//
import Overlays from '@/components/Overlays'

import { windowStore } from '@/utilities/window/store'

import { sidebarMenuItems } from '@/utilities/sidebarMenuItems/Management'

export default {
  components: {
    DesktopSideBar,
    Page,
    AppContent,
    //
    TopNav,
    Sidebar,
    Overlays
  },

  data: function() {
    return {
      theme: 'theme-light'
    }
  },

  async beforeMount() {},

  computed: {
    ...mapGetters('user', ['authUser', 'loggedIn', 'hoaId']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapState({
      subscription: state => state.subscription.subscription,
      hoaFeatures: state => state.subscription.hoaFeatures,
      hoaAddOnFeatures: state => state.subscription.selectedHoaId
    }),
    sidebarMenuItems() {
      return sidebarMenuItems({
        hoaId: this.hoaId
      })
    }
  },
  mounted() {
    this.$store.dispatch('alerts/retrieveUnreadListOnRepeat')
  },
  beforeCreate() {
    windowStore.dispatch('init')
  }
}
</script>
