import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/accountspayable',
    name: 'accountspayable',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ar-homeownersowefees',
    name: 'ar-homeownersowefees',
    component: () =>
      import(
        /* webpackChunkName: "accountsreceivable" */ '@/pages/Management/accounts/accountsreceivable/HomeownersOweFeesList/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ar-miscellaneousreceipts',
    name: 'ar-miscellaneousreceipts',
    component: () =>
      import(
        /* webpackChunkName: "accountsreceivable" */ '@/pages/Management/accounts/accountsreceivable/MiscellaneousReceiptList/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/receipttabs/:id',
    name: 'receipttabs',
    component: () =>
      import(
        /* webpackChunkName: "accountsreceivable" */ '@/pages/Management/accounts/accountsreceivable/MiscellaneousReceiptList/details/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'receiptDetails',
        name: 'receipt.receiptDetails',
        component: () =>
          import(
            /* webpackChunkName: "accountsreceivable" */ '@/pages/Management/accounts/accountsreceivable/MiscellaneousReceiptList/details/tabs/miscellaneousReceiptDetails/index.vue'
          )
      },
      {
        path: 'receiptDistributions',
        name: 'receipt.receiptDistributions',
        component: () =>
          import(
            /* webpackChunkName: "accountsreceivable" */ '@/pages/Management/accounts/accountsreceivable/MiscellaneousReceiptList/details/tabs/miscellaneousReceiptDistributions/index.vue'
          )
      }
    ]
  },
  {
    path: '/ap-invoicesthirtydays/:filtered',
    name: 'ap-invoicesthirtydays',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-invoicespendingcoding/:filtered',
    name: 'ap-invoicespendingcoding',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-invoicespendingapproval/:filtered',
    name: 'ap-invoicespendingapproval',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-invoicespendingposting/:filtered',
    name: 'ap-invoicespendingposting',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/invoices/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-pettycashreceipts',
    name: 'ap-pettycashreceipts',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/PettyCashReceipts.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-boardreimbursements',
    name: 'ap-boardreimbursements',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/BoardReimbursements.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/invoices/:id',
    name: 'invoices',
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ '@/pages/Management/accounts/accountspayable/invoicedetail/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: '/ap-invoice-detail/:id',
        name: 'invoice.invoicedetail',
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/pages/Management/accounts/accountspayable/invoicedetail/tabs/invoiceDetail/InvoiceDetail.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/invoicecharges/:id',
        name: 'invoice.invoicecharges',
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/pages/Management/accounts/accountspayable/invoicedetail/tabs/invoiceCharges/List.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/invoiceapprovals/:id',
        name: 'invoice.invoiceapprovals',
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/pages/Management/accounts/accountspayable/invoicedetail/tabs/invoiceApprovals/List.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/invoicenotes/:id',
        name: 'invoice.invoicenotes',
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/pages/Management/accounts/accountspayable/invoicedetail/tabs/invoiceNotes/List.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/invoiceattachments/:id',
        name: 'invoice.invoiceattachments',
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/pages/Management/accounts/accountspayable/invoicedetail/tabs/invoiceAttachments/List.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: '/invoicemessages/:id',
        name: 'invoice.invoicemessages',
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/pages/Management/accounts/accountspayable/invoicedetail/tabs/invoiceMessages/List.vue'
          ),
        beforeEnter: authGuard
      }
    ]
  },
  {
    path: '/ap-invoice-add-note/:id/:shownotes',
    name: 'ap-invoice-add-note',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/invoicedetail/tabs/invoiceDetail/InvoiceDetail.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-invoice-set-approved/:id/:setapproved',
    name: 'ap-invoice-set-approved',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/invoicedetail/tabs/invoiceDetail/InvoiceDetail.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ap-invoice-quickapprove/:id',
    name: 'ap-invoice-quickapprove',
    component: () =>
      import(
        /* webpackChunkName: "accountspayable" */ '@/pages/Management/accounts/accountspayable/quickapprove/QuickApprove.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/operatingbankaccount',
    name: 'operatingbankaccount',
    component: () =>
      import(
        /* webpackChunkName: "assets" */ '@/pages/Management/accounts/assets/OperatingBankAccount.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/reservebankaccount',
    name: 'reservebankaccount',
    component: () =>
      import(
        /* webpackChunkName: "assets" */ '@/pages/Management/accounts/assets/ReserveBankAccount.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/ccrcompliancefund',
    name: 'ccrcompliancefund',
    component: () =>
      import(
        /* webpackChunkName: "assets" */ '@/pages/Management/accounts/assets/CcrComplianceFund.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/assetspettycash',
    name: 'assetspettycash',
    component: () =>
      import(
        /* webpackChunkName: "assets" */ '@/pages/Management/accounts/assets/AssetsPettyCash.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/billpayapproval',
    name: 'billpayapproval',
    component: () =>
      import(
        /* webpackChunkName: "billpayapproval" */ '@/pages/Management/accounts/billpayapproval/BillPayApproval.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/reports/:filtered',
    name: 'reports',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/pages/Management/accounts/reports/List.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reportsall',
    name: 'reportsall',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/pages/Management/accounts/reports/ListAll.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reportsFinancial',
    name: 'reportsFinancial',
    component: () =>
      import(
        /* webpackChunkName: "reportsFinancial" */ '@/pages/Management/accounts/reportsFinancial/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/budgetbuilder',
    name: 'budgetbuilder',
    component: () =>
      import(
        /* webpackChunkName: "budgetbuilder" */ '@/pages/Management/accounts/budgetbuilder/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/budgetaccounts/:id',
    name: 'budgetaccounts',
    component: () =>
      import(
        /* webpackChunkName: "budgetbuilder" */ '@/pages/Management/accounts/budgetbuilder/budgetaccounts/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/budgetamounts/:id',
    name: 'budgetamounts',
    component: () =>
      import(
        /* webpackChunkName: "budgetbuilder" */ '@/pages/Management/accounts/budgetbuilder/budgetamounts/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () =>
      import(
        /* webpackChunkName: "generalledger" */ '@/pages/Management/accounts/generalledger/GeneralLedger/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/accounts/:id',
    name: 'account',
    component: () =>
      import(
        /* webpackChunkName: "generalledger" */ '@/pages/Management/accounts/generalledger/GeneralLedgerAccount.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/managechartofaccounts',
    name: 'managechartofaccounts',
    component: () =>
      import(
        /* webpackChunkName: "generalledger" */ '@/pages/Management/accounts/generalledger/chartofaccounts/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/posttoledger',
    name: 'posttoledger',
    component: () =>
      import(
        /* webpackChunkName: "generaledger" */ '@/pages/Management/accounts/generalledger/pendingposttogl/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/homeownersowefunds',
    name: 'homeownersowefunds',
    component: () =>
      import(
        /* webpackChunkName: "collections" */ '@/pages/Management/accounts/collections/HomeownersOweFunds.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/homeownersoweassessments',
    name: 'homeownersoweassessments',
    component: () =>
      import(
        /* webpackChunkName: "collections" */ '@/pages/Management/accounts/collections/HomeownersOweAssessments.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/homeownersowenonassessments',
    name: 'homeownersowenonassessments',
    component: () =>
      import(
        /* webpackChunkName: "collections" */ '@/pages/Management/accounts/collections/HomeownersOweNonAssessments.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/pendingtrusteesales',
    name: 'pendingtrusteesales',
    component: () =>
      import(
        /* webpackChunkName: "collections" */ '@/pages/Management/accounts/collections/PendingTrusteeSales.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/bankruptcies',
    name: 'bankruptcies',
    component: () =>
      import(
        /* webpackChunkName: "collections" */ '@/pages/Management/accounts/collections/Bankruptcies.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/smallclaims',
    name: 'smallclaims',
    component: () =>
      import(
        /* webpackChunkName: "collections" */ '@/pages/Management/accounts/collections/SmallClaims.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/accountsonpaymentplans',
    name: 'accountsonpaymentplans',
    component: () =>
      import(
        /* webpackChunkName: "collections" */ '@/pages/Management/accounts/collections/AccountsOnPaymentPlans.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/accountswithattorney',
    name: 'accountswithattorney',
    component: () =>
      import(
        /* webpackChunkName: "collections" */ '@/pages/Management/accounts/collections/AccountsWithAttorney.vue'
      ),
    beforeEnter: authGuard
  },
  {
    // If no tab is specifed, go to overview
    path: '',
    redirect: 'invoice.invoicedetail'
  },
  {
    // If path doesn't match, go to overview
    path: '*',
    redirect: 'invoice.invoicedetail'
  }
]
