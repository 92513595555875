import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/userSettings',
    name: 'userSettings',
    component: () =>
      import(
        /* webpackChunkName: "usersettings" */ '@/pages/Shared/accounts/userSettings/Main.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'profile',
        name: 'userSettings.profile',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Shared/accounts/userSettings/profile/Profile.vue'
          )
      },
      {
        path: 'accountsecurity',
        name: 'userSettings.accountsecurity',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Shared/accounts/userSettings/accountSecurity/AccountSecurity.vue'
          )
      },
      {
        path: 'hoaaccounts',
        name: 'userSettings.hoaaccounts',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Shared/accounts/userSettings/hoaaccounts/HoaAccounts.vue'
          )
      },
      {
        path: 'alertsubscriptions',
        name: 'userSettings.alertsubscriptions',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Shared/accounts/userSettings/alertSubscriptions/List.vue'
          )
      },
      {
        path: 'twofactorauthentication',
        name: 'userSettings.twofactorauthentication',
        component: () =>
          import(
            /* webpackChunkName: "units" */ '@/pages/Shared/accounts/userSettings/twoFactorAuthentication/index.vue'
          )
      }
    ]
  }
]
