import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/escrow',
    name: 'escrow',
    component: () => import(/* webpackChunkName: "escrow" */ '@/pages/Management/escrow/List.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/escrowTabs/:id/:name',
    name: 'escrowTabs',
    component: () =>
      import(/* webpackChunkName: "escrow" */ '@/pages/Management/escrow/details/index.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: 'escrowDetails',
        name: 'escrow.escrowDetails',
        component: () =>
          import(
            /* webpackChunkName: "escrow" */ '@/pages/Management/escrow/details/tabs/escrowDetails/index.vue'
          )
      },
      {
        path: 'escrowConversations',
        name: 'escrow.escrowConversations',
        component: () =>
          import(
            /* webpackChunkName: "escrow" */ '@/pages/Management/escrow/details/tabs/conversations/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'escrowDetails'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'escrowDetails'
      }
    ]
  }
]
