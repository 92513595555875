import authGuard from '@/router/auth-guard'

export const details = [
  {
    path: '/boardMeetingDetails/:id',
    name: 'boardMeetingDetails',
    component: () =>
      import(
        /* webpackChunkName: "boardMeetingDetails" */ '@/pages/Management/boardMeetings/Details/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'detail',
        name: 'boardMeetingsDetails.boardMeetingsDetail',
        component: () =>
          import(
            /* webpackChunkName: "boardMeetingDetails.boardMeetingDetail" */

            '@/pages/Management/boardMeetings/Details/components/Detail/index.vue'
          )
      },
      {
        path: 'detail/edit',
        name: 'boardMeetingsDetails.boardMeetingsDetail',
        component: () =>
          import(
            /* webpackChunkName: "boardMeetingDetails.boardMeetingDetail" */

            '@/pages/Management/boardMeetings/Details/components/Detail/index.vue'
          )
      },
      {
        path: 'agenda',
        name: 'boardMeetingsDetails.boardMeetingsAgenda',
        component: () =>
          import(
            /* webpackChunkName: "boardMeetingDetails.boardMeetingAgenda" */

            '@/pages/Management/boardMeetings/Details/components/Agenda/index.vue'
          )
      },
      {
        path: 'boardPackageLayout',
        name: 'boardMeetingsDetails.boardMeetingsBoardPackageLayout',
        component: () =>
          import(
            /* webpackChunkName: "boardMeetingDetails.boardMeetingBoardPackage" */

            '@/pages/Management/boardMeetings/Details/components/BoardPackageLayout/index.vue'
          )
      },
      {
        path: 'minutes',
        name: 'boardMeetingsDetails.boardMeetingsMinutes',
        component: () =>
          import(
            /* webpackChunkName: "boardMeetingDetails.boardMeetingMinutes" */

            '@/pages/Management/boardMeetings/Details/components/Minutes/index.vue'
          )
      },
      {
        // If no tab is specifed, go to detail page
        path: '',

        // redirect to path
        redirect: 'detail'
      },
      {
        // If path doesn't match, go to detail page
        path: '*',
        redirect: 'detail'
      }
    ]
  }
]
