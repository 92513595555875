/*
  '14': {
    name: 'Vendors',
    includes: { 
      '15': 'Work Order Flows', 
      '72': 'Management' 
    }
  }
*/

import { authFeatureGuard } from '@/router/auth-feature-guard'

export default [
  {
    path: '/vendorRequests',
    name: 'vendorRequests',
    component: () =>
      import(
        /* webpackChunkName: "vendorRequests" */
        '@/pages/Management/vendors/List.vue'
      ),
    beforeEnter: authFeatureGuard(14)
  },
  {
    path: '/vendorRequests/:id',
    component: () =>
      import(
        /* webpackChunkName: "vendorRequests" */ '@/pages/Management/vendors/tabs/VendorRequestDetails/details/index.vue'
      ),
    beforeEnter: authFeatureGuard(14),
    children: [
      {
        path: 'vendorRequestDetails',
        name: 'vendorRequests.vendorRequestDetails',
        component: () =>
          import(
            /* webpackChunkName: "vendorRequests" */ '@/pages/Management/vendors/tabs/VendorRequestDetails/details/tabs/requestDetails/index.vue'
          )
      },
      {
        path: 'vendorRequestAttachments',
        name: 'vendorRequests.vendorRequestAttachments',
        component: () =>
          import(
            /* webpackChunkName: "vendorRequests" */ '@/pages/Management/vendors/tabs/VendorRequestDetails/details/tabs/attachments/List.vue'
          )
      },
      {
        path: 'vendorRequestConversations',
        name: 'vendorRequests.vendorRequestConversations',
        component: () =>
          import(
            /* webpackChunkName: "vendorRequests" */ '@/pages/Management/vendors/tabs/VendorRequestDetails/details/tabs/conversations/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'vendorRequests'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'vendorRequests'
      }
    ]
  }
]
