//

import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/account/createlinked',
    name: 'accountcreatelinked',
    component: () =>
      import(
        /* webpackChunkName: "accountcreatelinked" */ '@/pages/Management/plaid/LinkedFormCreate/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/account/createunlinked',
    name: 'accountcreateunlinked',
    component: () =>
      import(
        /* webpackChunkName: "accountcreateunlinked" */ '@/pages/Management/plaid/UnlinkedFormCreate/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/account/bankaccountlisting/:id',
    name: 'bankaccountlisting',
    component: () =>
      import(
        /* webpackChunkName: "bankaccountlisting" */ '@/pages/Management/plaid/BankAccountListingFormDetails.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/account/createBankAccountListing',
    name: 'createBankAccountListing',
    component: () =>
      import(
        /* webpackChunkName: "createBankAccountListing" */ '@/pages/Management/plaid/BankAccountListingFormCreate/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/bankAccountReconcile/:id',
    name: 'bankAccountReconcile',
    component: () =>
      import(
        /* webpackChunkName: "bankAccountsReconcile" */ '@/pages/Management/plaid/BankAccountReconcile/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/bankAccountReconcileDetailList',
    name: 'bankAccountReconcileDetailList',
    component: () =>
      import(
        /* webpackChunkName: "bankAccountReconcileDetailList" */ '@/pages/Management/plaid/BankAccountReconcileDetailList/index.vue'
      ),
    beforeEnter: authGuard
  }
]

//
