<template>
  <div
    overlays-container
    v-if="on"
    :style="{
      background: 'rgba(200,200,200,.3)',

      position: 'fixed',
      zIndex: 10000000,
      top: 0,
      left: 0,

      // height: '100vh',
      width: '100vw'
    }"
  >
    <component v-if="typeof component === 'object'" :is="component" v-bind="props"></component>
  </div>
</template>

<script>
/*
  import { overlaysStore } from '@/components/Overlays/store'

  await overlaysStore.dispatch ('show', {
    component: CustomModal,
    props: {
      toggle: true
    }
  });
*/
import { overlaysStore } from '@/components/Overlays/store'

import Modal from '@/components/Modal'

export default {
  components: {
    Modal
  },
  data() {
    return {
      on: false,
      component: false,
      props: {}
    }
  },
  mounted() {
    this.unsubOverlaysStore = overlaysStore.subscribe(mutation => {
      const mType = mutation.type

      if (mType === 'show') {
        this.component = mutation.payload.component
        this.props = mutation.payload.props
        this.on = true
      } else if (mType === 'hide') {
        this.component = false
        this.props = {}
        this.on = false
      }
    })
  }
}
</script>
