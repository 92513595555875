<template>
  <svg
    width="32px"
    height="37px"
    viewBox="3 0 30 35"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="0"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="none"
      stroke-linejoin="none"
    >
      <g transform="translate(-9.000000, -609.000000)" :stroke="stroke" stroke-width="2">
        <g transform="translate(13.000000, 15.000000)">
          <g transform="translate(2.000000, 599.000000)">
            <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
