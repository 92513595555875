<template>
  <PageContentLayoutOnly :aria-label="$t('architecturalRequests.title')" role="region">
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      :onUpdate="updateTable"
      headerColor="#FFF"
      condensed
    />
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import _get from 'lodash/get'
import _ from 'lodash'
import kms from '@/services/kms'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import SimpleButton from '@/components/buttons/Simple'
import moment from 'moment'
import { statusTile } from '@/utilities/architectural/tile/status'
import { notifyProblem } from '@/services/notify'
import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'

export default {
  name: 'Architectural',

  props: {
    // for purposes of testing
    kms: {
      type: Object,
      default: () => {
        return kms
      }
    }
  },

  components: { PageContentLayoutOnly, ModernTable },

  data: () => ({
    loading: false,
    staus: '',
    selectedRow: null,
    architecturalRequestList: null,
    region: {},
    filters: {
      show: true
    },
    rows: [],
    columns: [
      {
        field: 'id',
        label: 'ID',
        width: '5%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'category',
        label: 'Category',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'description',
        label: 'Description',
        width: '20%',
        sortable: true,
        searchable: true
      },
      {
        field: 'date',
        label: 'Date Submitted',
        width: '10%',
        date: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'status',
        label: 'Status',
        width: '10%',
        sortable: false,
        selected: 'Pending',
        searchable: [
          {
            label: 'All',
            filter: ''
          },
          {
            label: 'Approved',
            filter: 'Approved'
          },
          {
            label: 'Pending',
            filter: 'Pending'
          },
          {
            label: 'My Pending',
            filter: 'Pending'
          },
          {
            label: 'Denied',
            filter: 'Denied'
          }
        ]
      },
      {
        field: 'details',
        label: 'Actions',
        width: '5%'
      }
    ]
  }),

  methods: {
    getArchitecturalRequests: async function() {
      const hoaID = _get(this, ['user', 'selectedHoaId'], null)
      if (!['number', 'string'].includes(typeof hoaID)) {
        console.error('hoaID could not be determined')
        notifyProblem('The list could not be retrieved.')
        return
      }

      const ownerID = _get(this, ['unit', 'primaryOwner', 'ownerID'], null)
      if (!['number', 'string'].includes(typeof ownerID)) {
        console.error('ownerID could not be determined')
        notifyProblem('The list could not be retrieved.')
        return
      }

      try {
        const status = this.status

        const { list } = await archReqs.dispatch('List', {
          params: {
            hoaID,
            ownerID,
            approvedStatus: status == 'Approved' ? true : null,
            pendingStatus: status == 'Pending' ? true : null,
            deniedStatus: status == 'Denied' ? true : null,
            pendingNotVotedStatus: status == 'My Pending' ? true : null
          }
        })

        console.debug('list basic arch sub=' + JSON.stringify(list))

        this.architecturalRequestList = list
      } catch (ex) {
        notifyProblem(ex)
        return
      }

      if (!Array.isArray(this.architecturalRequestList)) {
        console.error('architecturalRequests is not an array', this.architecturalRequestList)
        notifyProblem('The list could not be retrieved.')
        return
      }

      this.determineRows()
    },

    async updateTable({ filtersData }) {
      const status = filtersData.status

      if (status !== this.status) {
        this.status = status

        await this.getArchitecturalRequests()
      }
    },

    determineRows: function() {
      var dateFormat = 'LL'
      var rows = this.orderedRequests.map(entry => {
        const id = _get(entry, 'architecturalSubmissionID', null)

        const statusText = _get(entry, 'status', '')
        const { status } = statusTile({ statusText })

        var date = _get(entry, 'submissionDate', '')
        try {
          date = moment(date).format(dateFormat)
        } catch (exception) {
          console.error(exception)
        }

        return {
          id,
          category: _get(entry, 'architecturalCategoryName', ''),
          description: _get(entry, 'description', ''),
          date: date,
          status,
          details: {
            component: SimpleButton,
            props: {
              to: {
                path: `/architecturaltabs/${id}/architecturalRequestDetails`,
                params: {
                  id
                }
              },
              text: 'details'
            }
          }
        }
      })

      this.rows = rows
    }
  },

  watch: {
    unit: 'getArchitecturalRequests',
    user: 'getArchitecturalRequests'
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      user: state => state.user
    }),
    orderedRequests: function() {
      return _.orderBy(this.architecturalRequestList, 'submissionDate', 'desc')
    }
  },

  mounted() {
    this.getArchitecturalRequests()
  },

  i18n: {
    messages: {
      en: { architecturalRequests: { title: 'Architectural Requests' } }
    }
  }
}
</script>

<style></style>
